<template>
  <div class="container-xxl d-flex flex-column justify-content-center">
    <div class="row row-cards">
      <div class="col-sm-6 col-lg-3">
                    <div class="card card-sm">
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <span class="bg-blue text-white avatar"><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M14.274 10.291a4 4 0 1 0 -5.554 -5.58m-.548 3.453a4.01 4.01 0 0 0 2.62 2.65"></path>
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 1.147 .167m2.685 2.681a4 4 0 0 1 .168 1.152v2"></path>
                                <line x1="3" y1="3" x2="21" y2="21"></line>
                              </svg>
                            </span>
                          </div>
                          <div class="col">
                            <div class="font-weight-medium">
                              {{ $t('bansPage.headBlock.allBans') }}
                            </div>
                            <div class="text-muted">
                              {{allCountBans}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">{{ $t('bansPage.bansTable.headTitle') }}</h3>
                  </div>
                  <div class="card-body border-bottom py-3">
                    <a v-if="!UserInfo.server || UserInfo.server.status && UserInfo.server.status != 2" @click="validate()" href="#" class="btn btn-outline-info w-100">
                      {{ $t('bansPage.bansTable.addBanButton') }}
                    </a>
                    <a v-if="UserInfo.server && UserInfo.server.status && UserInfo.server.status == 2" data-bs-toggle="modal" data-bs-target="#modal-add-ban" href="#" class="btn btn-outline-info w-100">
                      {{ $t('bansPage.bansTable.addBanButton') }}
                    </a>
                    <hr>
                    <div class="d-flex">
                      <div class="text-muted">
                        {{ $t('bansPage.bansTable.showBans_01') }}
                        <div class="mx-2 d-inline-block">
                          <input v-model="showCountLimit" v-on:input="showCount(showCountLimit)" type="text" class="form-control form-control-sm" value="8" size="3" aria-label="Invoices count">
                        </div>
                        {{ $t('bansPage.bansTable.showBans_02') }}
                      </div>
                      <div class="ms-auto text-muted">
                        {{ $t('bansPage.bansTable.searchBans') }}
                        <div class="ms-2 d-inline-block">
                          <input v-model="searchString" @change="search()" v-on:input="search()" type="text" class="form-control form-control-sm" aria-label="Search invoice">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="UserInfo.server && UserInfo.server.active == 0" class="alert alert-danger" role="alert">
                    {{ $t('headerPanel.linkServer.notice.serverBlocked.blockText') }}
                  </div>
                  <div class="table-responsive">
                    <table class="table card-table table-vcenter text-nowrap datatable">
                      <thead>
                        <tr>
                          <th class="w-1">{{ $t('bansPage.bansTable.tableCols.number') }}
                          </th>
                          <th v-if="UserInfo.server && UserInfo.server.status && UserInfo.server.status == 2 && UserInfo.server.active"><center>{{ $t('bansPage.bansTable.tableCols.serverInit') }}</center></th>
                          <th>{{ $t('bansPage.bansTable.tableCols.playerName') }}</th>
                          <th v-if="UserInfo.server && UserInfo.server.status && UserInfo.server.status == 2 && UserInfo.server.active"><center>{{ $t('bansPage.bansTable.tableCols.steamid') }}</center></th>
                          <th>{{ $t('bansPage.bansTable.tableCols.reason') }}</th>
                          <th>{{ $t('bansPage.bansTable.tableCols.timeBan') }}</th>
                          <th>EAC</th>
                          <th v-if="UserInfo.id && UserInfo.server"></th>
                          <th>{{ $t('bansPage.bansTable.tableCols.created') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(ban, index) in bansToShow" :key="index">
                          <td><span class="text-muted">{{ ban.banId }}</span></td>
                          <td v-if="ban.serverInit && UserInfo.server && UserInfo.server.status == 2 && UserInfo.server.active">
                            <button href="#" @click="selectServerInit(ban.serverInit.serverName, ban.serverInit.serverOwnerDiscord)" data-bs-toggle="modal" data-bs-target="#modal-serverInit" class="btn btn-ghost-info w-100">
                              {{ ban.serverInit.serverName }}
                            </button>
                          </td>
                          <td>
                            {{ ban.playerName }}
                          </td>
                          <td v-if="UserInfo.server && UserInfo.server.status == 2 && UserInfo.server.active">
                            <a class="btn btn-ghost-info active w-100" :href="`https://steamid.io/lookup/${ban.steamid}`" target="_blank">
                              {{ ban.steamid }}
                            </a>
                          </td>
                          <td>
                            {{ $t('bansPage.bansTable.tableRows.reasons.' + parseInt(ban.reason)) }}
                          </td>
                          <td>
                            <span v-if="ban.permBan == 1" class="badge bg-red">{{ $t('bansPage.bansTable.tableRows.permBan') }}</span>
                            <span v-if="ban.permBan == 0" class="badge bg-primary">{{ ban.timeBan }}</span>
                          </td>
                          <td>
                            <svg v-if="ban.devBlock == 0" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="green" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            <svg v-if="ban.devBlock == 1" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="DarkRed" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M5 12l5 5l10 -10"></path>
                            </svg>
                          </td>
                          <td v-if="UserInfo.server">
                            <span class="dropdown">
                              <button :disabled="ban.serverInit && UserInfo.server && UserInfo.server.id != ban.serverInit.serverId && !UserInfo.server.active && UserInfo.dev == 0" class="btn dropdown-toggle align-text-top" data-bs-boundary="viewport" data-bs-toggle="dropdown">{{ $t('bansPage.bansTable.tableRows.actionButton.name') }}</button>
                              <div class="dropdown-menu dropdown-menu-end">
                                <a @click="selectBanEdivence(ban.evidence)" v-if="ban.serverInit && UserInfo.server && UserInfo.server.id == ban.serverInit.serverId || UserInfo.dev == 1" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-edivence-ban">
                                  {{ $t('bansPage.bansTable.tableRows.actionButton.showEdivence') }}
                                </a>
                                <a @click="openUpdateBan(ban.id, ban.playerName, ban.steamid, ban.reason, ban.timeBan, ban.permBan, ban.evidence)" v-if="ban.serverInit && UserInfo.server && UserInfo.server.id == ban.serverInit.serverId || UserInfo && UserInfo.dev == 1" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modal-update-ban" href="#">
                                  {{ $t('bansPage.bansTable.tableRows.actionButton.updateBan') }}
                                </a>
                                <hr v-if="ban.serverInit && UserInfo.server && UserInfo.server.id == ban.serverInit.serverId && UserInfo.dev == 1" style="margin: 0.1rem 0;">
                                <a @click="selectBanId(ban.id)" v-if="UserInfo.dev == 1" class="dropdown-item text-warning" data-bs-toggle="modal" data-bs-target="#modal-dev-update" href="#">
                                  {{ $t('bansPage.bansTable.tableRows.actionButton.updateBan') }}
                                </a>
                                <hr v-if="ban.serverInit && UserInfo.server && UserInfo.server.id == ban.serverInit.serverId || UserInfo.dev == 1" style="margin: 0.1rem 0;">
                                <a @click="selectBanId(ban.id)" v-if="ban.serverInit && UserInfo.server && UserInfo.server.id == ban.serverInit.serverId || UserInfo.dev == 1" data-bs-toggle="modal" data-bs-target="#modal-confirm-delete" class="dropdown-item text-red" href="#">
                                  {{ $t('bansPage.bansTable.tableRows.actionButton.deleteBan') }}
                                </a>
                              </div>
                            </span>
                          </td>
                          <td>
                            {{ ban.created }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer d-flex align-items-center">
                    <p class="m-0 text-muted" @click="check()">{{ $t('bansPage.bansTable.isShowBans_01') }} <span>{{showCountLimit}}</span> {{ $t('bansPage.bansTable.isShowBans_02') }} <span>{{allCountBans}}</span> {{ $t('bansPage.bansTable.isShowBans_03') }}</p>
                    <ul class="pagination m-0 ms-auto">
                      <li class="page-item" v-bind:class="{ disabled: previousButton }" @click="prevPage()">
                        <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
                          <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
                          {{ $t('bansPage.bansTable.prevButton') }}
                        </a>
                      </li>
                      <li class="page-item" @click="selectPage" v-for="index in totalPageCount" :key="index" v-bind:class="{ active: selectedPage + 1 == index }"><a class="page-link" href="#">{{ index }}</a></li>
                      <li class="page-item" v-bind:class="{ disabled: nextButton }" @click="nextPage()">
                        <a class="page-link" href="#">
                          {{ $t('bansPage.bansTable.nextButton') }} <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- ServerInit modal -->
            <div class="modal modal-blur fade" id="modal-serverInit" tabindex="-1" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">{{ $t('bansPage.modalServerInit.title') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    {{ $t('bansPage.modalServerInit.text1') }} <span class="text-info">{{ selectServerInitData.name }}</span>{{ $t('bansPage.modalServerInit.text2') }}
                    <hr>
                    <a class="btn btn-outline-info w-100" :href="selectServerInitData.discord">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-discord" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <circle cx="9" cy="12" r="1"></circle>
                        <circle cx="15" cy="12" r="1"></circle>
                        <path d="M7.5 7.5c3.5 -1 5.5 -1 9 0"></path>
                        <path d="M7 16.5c3.5 1 6.5 1 10 0"></path>
                        <path d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-1 2.5"></path>
                        <path d="M8.5 17c0 1 -1.356 3 -1.832 3c-1.429 0 -2.698 -1.667 -3.333 -3c-.635 -1.667 -.476 -5.833 1.428 -11.5c1.388 -1.015 2.782 -1.34 4.237 -1.5l1 2.5"></path>
                      </svg>
                      {{ selectServerInitData.name }}
                    </a>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn" data-bs-dismiss="modal">{{ $t('bansPage.modalServerInit.closeButton') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- -->

            <!-- Modal Add Ban -->
            <div class="modal modal-blur fade" id="modal-add-ban" tabindex="-1" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">{{ $t('bansPage.modalAddBan.title') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.playerName') }}</label>
                          <input v-model="modalAddBan.name" type="text" class="form-control">
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.steamid') }}</label>
                          <input v-model="modalAddBan.steamid" type="number" class="form-control">
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.reasons.name') }}</label>
                          <select v-model="modalAddBan.reason" class="form-select">
                            <option v-for="res in 8" :key="res" :value="res" selected="">{{ $t('bansPage.modalAddBan.form.reasons.' + res) }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <label class="form-label">{{ $t('bansPage.modalAddBan.form.timeBan.name') }}</label>
                    <div class="form-selectgroup-boxes row mb-3">
                      <div class="col-lg-6">
                        <label @click="selectTimeBan(1)" class="form-selectgroup-item">
                          <input type="radio" name="report-type" class="form-selectgroup-input" value="0" checked="">
                          <span class="form-selectgroup-label d-flex align-items-center p-3">
                            <span class="me-3">
                              <span class="form-selectgroup-check"></span>
                            </span>
                            <span class="form-selectgroup-label-content">
                              <span class="form-selectgroup-title strong mb-1">{{ $t('bansPage.modalAddBan.form.timeBan.radio_1_1') }}</span>
                              <span class="d-block text-muted">{{ $t('bansPage.modalAddBan.form.timeBan.radio_1_2') }}</span>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div class="col-lg-6">
                        <label @click="selectTimeBan(2)" class="form-selectgroup-item">
                          <input v-model="modalAddBan.perm" type="radio" name="report-type" value="1" class="form-selectgroup-input">
                          <span class="form-selectgroup-label d-flex align-items-center p-3">
                            <span class="me-3">
                              <span class="form-selectgroup-check"></span>
                            </span>
                            <span class="form-selectgroup-label-content">
                              <span class="form-selectgroup-title strong mb-1">{{ $t('bansPage.modalAddBan.form.timeBan.radio_2_1') }}</span>
                              <span class="d-block text-muted">{{ $t('bansPage.modalAddBan.form.timeBan.radio_2_2') }}</span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <label class="form-label">{{ $t('bansPage.modalAddBan.form.onlyDevs.name') }}</label>
                    <label class="form-check form-switch">
                      <input v-model="modalAddBan.onlyDevs" class="form-check-input" type="checkbox" checked="">
                      <span class="form-check-label">{{ $t('bansPage.modalAddBan.form.onlyDevs.radio_1_2') }}</span>
                    </label>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div v-if="modalAddBan.perm == 0" class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.timeBanDate') }}</label>
                          <input v-model="modalAddBan.date" type="date" class="form-control">
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div>
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.edivence') }}</label>
                          <textarea v-model="modalAddBan.edivence" class="form-control" rows="3"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                      {{ $t('bansPage.modalAddBan.form.cancelButton') }}
                    </a>
                    <a href="#" @click="addBan()" data-bs-dismiss="modal" class="btn btn-primary ms-auto">
                      <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                      {{ $t('bansPage.modalAddBan.form.addBanButton') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          <!-- -->

          <!-- Modal Update Ban -->
            <div class="modal modal-blur fade" id="modal-update-ban" tabindex="-1" style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">{{ $t('bansPage.modalUpdateBan.title') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.playerName') }}</label>
                          <input v-model="modalUpdateBan.name" type="text" class="form-control">
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.steamid') }}</label>
                          <input v-model="modalUpdateBan.steamid" type="text" class="form-control">
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.reasons.name') }}</label>
                          <select v-model="modalUpdateBan.reason" class="form-select">
                            <option v-for="res in 8" :key="res" :value="res"  selected="">{{ $t('bansPage.modalAddBan.form.reasons.' + res) }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <label class="form-label">{{ $t('bansPage.modalAddBan.form.timeBan.name') }}</label>
                    <div class="form-selectgroup-boxes row mb-3">
                      <div class="col-lg-6">
                        <label @click="selectTimeBanUpdate(1)" class="form-selectgroup-item">
                          <input type="radio" name="report-type" class="form-selectgroup-input" v-bind:checked="checkedTimeTypeUpdate.check1">
                          <span class="form-selectgroup-label d-flex align-items-center p-3">
                            <span class="me-3">
                              <span class="form-selectgroup-check"></span>
                            </span>
                            <span class="form-selectgroup-label-content">
                              <span class="form-selectgroup-title strong mb-1">{{ $t('bansPage.modalAddBan.form.timeBan.radio_1_1') }}</span>
                              <span class="d-block text-muted">{{ $t('bansPage.modalAddBan.form.timeBan.radio_1_2') }}</span>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div class="col-lg-6">
                        <label @click="selectTimeBanUpdate(2)" class="form-selectgroup-item">
                          <input type="radio" name="report-type" class="form-selectgroup-input" v-bind:checked="checkedTimeTypeUpdate.check2">
                          <span class="form-selectgroup-label d-flex align-items-center p-3">
                            <span class="me-3">
                              <span class="form-selectgroup-check"></span>
                            </span>
                            <span class="form-selectgroup-label-content">
                              <span class="form-selectgroup-title strong mb-1">{{ $t('bansPage.modalAddBan.form.timeBan.radio_2_1') }}</span>
                              <span class="d-block text-muted">{{ $t('bansPage.modalAddBan.form.timeBan.radio_2_2') }}</span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div v-if="modalUpdateBan.perm == 0" class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.timeBanDate') }}</label>
                          <input v-model="modalUpdateBan.date" type="date" class="form-control">
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div>
                          <label class="form-label">{{ $t('bansPage.modalAddBan.form.edivence') }}</label>
                          <textarea v-model="modalUpdateBan.edivence" class="form-control" rows="3"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
                      {{ $t('bansPage.modalAddBan.form.cancelButton') }}
                    </a>
                    <a href="#" @click="updateBan()" data-bs-dismiss="modal" class="btn btn-primary ms-auto">
                      <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                      {{ $t('bansPage.modalUpdateBan.updateBanButton') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          <!-- -->

          <!-- Modal confirm delete ban -->
            <div class="modal modal-blur fade" id="modal-confirm-delete" tabindex="-1" aria-hidden="true" style="display: none;">
              <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div class="modal-content">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  <div class="modal-status bg-danger"></div>
                  <div class="modal-body text-center py-4">
                    <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v2m0 4v.01"></path><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path></svg>
                    <h3>{{ $t('bansPage.modalDeleteBan.headerText') }}</h3>
                    <div class="text-muted">{{ $t('bansPage.modalDeleteBan.text') }}</div>
                  </div>
                  <div class="modal-footer">
                    <div class="w-100">
                      <div class="row">
                        <div class="col"><a href="#" class="btn w-100" data-bs-dismiss="modal">
                            {{ $t('bansPage.modalDeleteBan.cancelDeleteButton') }}
                          </a></div>
                        <div class="col"><a @click="deleteBan()" href="#" class="btn btn-danger w-100" data-bs-dismiss="modal">
                            {{ $t('bansPage.modalDeleteBan.acceptDeleteButton') }}
                          </a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <!-- -->

          <!-- edivence ban -->
          <div class="modal modal-blur fade" id="modal-edivence-ban" tabindex="-1" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ $t('bansPage.modalEdivence.title') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                  <div v-for="(edi, index) in currentEdivence" :key="index">
                    <center v-if="edi.indexOf('.png') != -1 || edi.indexOf('.jpg') != -1 || edi.indexOf('.jpeg') != -1"><a :href="edi" target="_blank"><img :src="edi" width="150px" height="150px"></a></center>
                    <a v-else-if="edi != 'None' && edi.indexOf('http') != -1" :href="edi" class="btn btn-outline-info w-100">
                      {{ $t('bansPage.modalEdivence.nameLink') }}{{ index + 1 }}
                    </a>
                    <p v-else>{{edi}}</p>
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn" data-bs-dismiss="modal">{{ $t('bansPage.modalEdivence.closeButton') }}</button>
                </div>
              </div>
            </div>
          </div>
          <!-- -->

          <!-- dev update -->
          <div class="modal modal-blur fade" id="modal-dev-update" tabindex="-1" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ $t('bansPage.modalDeveloper.title') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                          <div class="mb-3">
                            <label class="form-label">{{ $t('bansPage.modalDeveloper.isEAC') }}</label>
                            <div class="form-selectgroup">
                              <label class="form-selectgroup-item">
                                <input @change="changeEACStatus(0)" type="radio" name="icons" value="home" class="form-selectgroup-input">
                                <span class="form-selectgroup-label"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                   {{ $t('bansPage.modalDeveloper.isEACButton_1') }}</span>
                              </label>
                              <label class="form-selectgroup-item">
                                <input @change="changeEACStatus(1)" type="radio" name="icons" value="user" class="form-selectgroup-input">
                                <span class="form-selectgroup-label"><!-- Download SVG icon from http://tabler-icons.io/i/user -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5 12l5 5l10 -10"></path>
                                  </svg>
                                   {{ $t('bansPage.modalDeveloper.isEACButton_2') }}</span>
                              </label>
                            </div>
                          </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn" data-bs-dismiss="modal">{{ $t('bansPage.modalDeveloper.hideButton') }}</button>
                </div>
              </div>
            </div>
          </div>
          <!-- -->

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
    name: "BansPage",
    data: function () {
      return {
        searchString: null,
        oldSearchString: null,
        searchedBans: [],
        selectedPage: 0,
        bansPerPage: 0,
        banChunks: [],
        showCountLimit: 10,
        allCountBans: 0,
        selectServerInitData: {
          name: null,
          discord: null
        },
        selectedBan: null,
        modalAddBan: {
          name: null,
          steamid: null,
          reason: null,
          perm: 0,
          onlyDevs: 0,
          date: "2022-01-01",
          edivence: null
        },
        modalUpdateBan: {
          name: null,
          steamid: null,
          reason: null,
          perm: 0,
          date: "2022-01-01",
          edivence: null
        },
        checkedTimeTypeUpdate: {
          check1: null,
          check2: null
        },
        currentEdivence: null
      }
    },
    methods: {
      ...mapActions("bansInfo", ["LoadBansInfo"]),
        changeEACStatus(status) {
          this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "add_dev_block", banId: this.selectedBan, active: status}}).then((response) => {
            if (response.data.success) {
              this.loadBansData()
            }
          })
        },
        openUpdateBan(id, playerName, steamid, reason, timeBan, perm, edivence) {
          this.selectedBan = id

          this.selectTimeBanUpdate(perm)

          this.modalUpdateBan.id = id
          this.modalUpdateBan.name = playerName
          this.modalUpdateBan.steamid = steamid
          this.modalUpdateBan.reason = reason
          this.modalUpdateBan.perm = perm
          this.modalUpdateBan.date = timeBan
          this.modalUpdateBan.edivence = edivence.toString()
          this.modalUpdateBan.edivence = this.replaceAll(this.modalUpdateBan.edivence, ',', '\n')
        },
        replaceAll(string, search, replace) {
          return string.split(search).join(replace);
        },
        selectBanEdivence(edivence) {
          this.currentEdivence = edivence
        },
        updateBan() {
          if (!this.modalUpdateBan.name || !this.modalUpdateBan.steamid || !this.modalUpdateBan.reason) return this.notifyHandler('error', 'Ops!', this.$t('headerPanel.linkServer.notice.apiBattleMetrics.text'))
          console.log(this.modalUpdateBan)
          this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "update_ban", banInfo: this.modalUpdateBan}}).then((response) => {
            if (response.data.success) {
              this.loadBansData()
            }
          })
        },
        selectBanId(id) {
          this.selectedBan = id
        },
        deleteBan() {
          this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "delete_ban", banId: this.selectedBan}}).then((response) => {
            if (response.data.success) {
              this.loadBansData()
            }
          })
        },
        validate() {
          if (!this.UserInfo.server) {
            return this.notifyHandler('error', 'Ops!', 'Please, auth')
          }
        },
        selectTimeBan(type) {
          if (type == 1) {
            this.modalAddBan.perm = 0;
          } else {
            this.modalAddBan.perm = 1;
          }
        },
        onlyDevBan() {
          if (this.modalAddBan.onlyDevs) {
            this.modalAddBan.onlyDevs = 0;
          } else {
            this.modalAddBan.onlyDevs = 1;
          }
        },
        selectTimeBanUpdate(type) {
          console.log(type)
          if (type == 1) {
            this.modalUpdateBan.perm = 0;
            this.checkedTimeTypeUpdate.check1 = null;
            this.checkedTimeTypeUpdate.check2 = 1;
          } else {
            this.checkedTimeTypeUpdate.check1 = 1;
            this.checkedTimeTypeUpdate.check2 = null;
            this.modalUpdateBan.perm = 1;
          }
        },
        addBan() {
          if (!this.modalAddBan.name || !this.modalAddBan.steamid || !this.modalAddBan.reason) return this.notifyHandler('error', 'Ops!', this.$t('headerPanel.linkServer.notice.apiBattleMetrics.text'))
          this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "add_ban", banInfo: this.modalAddBan}}).then((response) => {
            if (response.data.success) {
              this.loadBansData()
            }
          })
        },
        selectServerInit(name, discord) {
          this.selectServerInitData.name = name
          this.selectServerInitData.discord = discord
        },
        search() {
            this.bansPerPage = this.showCountLimit;
            this.selectedPage = 0;
            if (
                
                this.oldSearchString !== this.searchString
            ) {
                this.searchedBans = this.allBans(null, this.searchString);
                this.banChunks = _.chunk(this.searchedBans, this.bansPerPage);
            }
            this.oldSearchString = this.searchString;
        },
        nextPage() {
            if (!this.nextButton) {
                this.selectedPage + 1 === this.totalPageCount ? this.selectedPage : (this.selectedPage += 1);
            }
        },
        prevPage() {
            if (!this.previousButton) {
                this.selectedPage + 1 > 1 ? (this.selectedPage -= 1) : this.selectedPage;
            }
        },
        selectPerPageCount(event) {
            this.selectedPage = 0;
            this.bansPerPage = event.target.value;
            this.banChunks = _.chunk(this.searchedBans, this.bansPerPage);
        },
        selectPage(event) {
            this.selectedPage = event.target.text - 1;
        },
        showCount(showCountLimit) {
            this.bansPerPage = showCountLimit;
            this.banChunks = _.chunk(this.searchedBans, this.bansPerPage);
        },
        notifyHandler(preset, heading, content) {
          this.$dtoast.pop({
            preset: preset,
            heading: heading,
            content:  content,
          })
        },
        check() {
          this.$dtoast.pop({
            preset: "info",
            heading: `Custom Heading`,
            content:  `Custom content`,
          })
        },
        loadBansData() {
          this.LoadBansInfo().then(response => {
              response;
              this.bansPerPage = 10;
              this.selectedPage = 0;
              this.searchedBans = this.allBans(null, null);
              this.banChunks = _.chunk(this.searchedBans, this.bansPerPage);
              this.allCountBans = this.getCountBans();
          }, error => {
              console.error(error)
          })
        }
    },
    created() {
        this.loadBansData()
    },
    computed: {
        ...mapState("bansInfo", ["BansInfo"]),
        ...mapState("userInfo", ["UserInfo"]),
        ...mapGetters("bansInfo", ["allBans", "getCountBans"]),
        bansToShow() {
            return this.banChunks[this.selectedPage];
        },
        totalPageCount() {
            return this.banChunks.length;
        },
        previousButton() {
            return !(this.totalPageCount + 1 === 1 || this.selectedPage !== 0);
        },
        nextButton() {
            return !(this.selectedPage + 1 < this.totalPageCount);
        },
    }
}
</script>